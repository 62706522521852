import React from 'react'
import loadable from '@loadable/component'
import useIntersect from '../../hooks/useIntersect'
import './vector-image.scss'

const Ulysses = loadable(() => import('./UlyssesSvg'))
const BudSketch = loadable(() => import('./BudSketchSvg'))

const VectorImage = React.memo(({ art = 'ulysses' }) => {
  const [intersectionRef, entry] = useIntersect({})

  return (
    <div
      ref={intersectionRef}
      alt='Ulysses, adult marijuana user. Enjoys fine Michigan Marijuana.'>
      {art === 'ulysses' && entry.isIntersecting && (
        <Ulysses className='animation-ulysses' />
      )}
      {art === 'budsketch' && entry.isIntersecting && (
        <BudSketch className='animation-bud' />
      )}
    </div>
  )
})

export default VectorImage
